/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

/* stylelint-disable declaration-no-important */

.ProductCard {
    flex-direction: column;
    box-shadow: 0 4px 12px 0 #0000000f;
    border-radius: 10px;
    height: 100%;
    transition: 0.5s;

    &-ImageWrapper {
        box-sizing: border-box;
        border-radius: 10px;
        background: white;

        @include mobile {
            height: 100px;
        }

        @include desktop {
            height: 230px;
        }

        .Image {
            position: relative;
            overflow: visible;
            padding: 0;

            &_isPlaceholder {
                inset-inline-start: 0px !important;
                z-index: 10;
            }
        }
    }

    &-ContentWrapper {
        height: 262px;
    }

    &-Content {
        height: 100%;
        min-height: 205px;
        background: unset;
        padding-block-end: 25px;
        padding-block-start: 25px;
        padding-inline: 20px;


        @include desktop {
            padding-inline: 25px;
            min-height: 200px;
        }

        @include mobile {
            padding-inline: 6px;
            padding-inline-end: 6px;
            padding-block-end: 10px;
        }


        @include mobile {
            min-height: auto;
        }
    }

    &-Name {
        color: var(--primary-blue-color);
        font-family: 'Roboto-Regular', sans-serif;
        font-size: 20px;
        line-height: 22px;
        text-align: center;
        white-space: inherit;
        font-weight: 600;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;

        @include mobile {
            text-overflow: ellipsis;
            font-size: 16px;
            min-height: 45px;
        }

        @include desktop {
            font-size: 22px;
            line-height: 26px;
            height: 47px;
            margin: 0;
        }
    }

    &-PriceWrapper {
        margin-block-start: 3px;
        margin-block-end: 15px;
        min-height: 36px;

        .ProductPrice_hasDiscount {
            .ProductPrice-PriceValue {
                color: #F43636;
                font-family: 'Roboto-bold', sans-serif;
            }
        }

        @include mobile {
            align-items: flex-start;
            flex-grow: 1;
            min-height: 16px;
        }
    }

    &-Price {
        text-align: center;
        font-size: 22px;
    }

    &-ActionsWrapper {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        flex-grow: 1;
        align-items: flex-end;
        margin: 0;

        @include mobile {
            flex-grow: initial;
        }
    }

    .ProductCard {
        &-QuantityWrapper {
            @include mobile {
                height: 38px;
                display: flex;
                align-items: center;
                border: 1px solid var(--primary-blue-color);
                border-radius: 5px;
                padding: 5px;
            }

            .CartItem-Qty {
                margin: 0;

                input {
                    pointer-events: auto;
                }
            }
        }

        &-AddToCart {
            @include mobile {
                font-size: 0;
                text-indent: -9999px;
                height: 38px;
                grid-column-gap: 0;
                -webkit-column-gap: 0;
                column-gap: 0;
            }
        }
    }

    .ProductPrice-PriceValue {
        color: #0A0903;
        font-size: 20px;
        line-height: 22px;
        font-weight: 700;
        font-family: 'Roboto-bold', sans-serif;

        @include desktop {
            font-size: 22px;
            line-height: 24px;
        }

        @include mobile {
            font-size: 14px;
        }
    }

    .CartItem-Qty {
        @include desktop {
            border-radius: 5px;
            padding: 4px;
            height: 48px;
            width: 110px;
            border: 1px solid #23346C;
        }

        input {
            width: 24px;
            height: 24px;
            min-width: 24px;
            min-height: 24px;
            font-size: 16px;
            color: var(--secondary-blue-color);
            border-radius: 5px;
            margin: 0 2px;
            padding-block-start: 2px;

            @include desktop {
                width: 34px;
                height: 34px;
            }
        }

        [type='button'] {
            width: 24px;
            height: 24px;
            border: solid 1px #e7f2f9;
            border-radius: 5px;

            @include desktop {
                width: 32px;
                height: 34px;
            }

            &:hover {
                background-color: #DDF7FE;
            }

            .AddIcon,
            .MinusIcon {
                fill: var(--primary-blue-color);
                width: 16px;
                height: 16px;

                @include desktop {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        [type='number'] {
            pointer-events: auto;
            font-size: 16px;
        }
    }

    &-OutOfStock {
        padding-inline-start: 25px;

        p {
            font-family: 'Roboto-Bold', sans-serif;
            color: var(--primary-blue-color);
            font-size: 20px;
        }
    }

    @include  mobile {
        padding-block-start: 10px;
    }
}

.hhel {
    @include desktop {
        height: 48px;
        grid-column-gap: 10px;
        -webkit-column-gap: 10px;
        column-gap: 10px;
        color: var(--primary-blue-color);
        background-color: var(--hollow-button-background);
    }

    @include mobile {
        font-size: 0;
        text-indent: -9999px;
        height: 38px;
        grid-column-gap: 0;
        -webkit-column-gap: 0;
        column-gap: 0;
        color: var(--primary-blue-color);
        background-color: var(--hollow-button-background);
    }
}

.hhel .CartIcon {
    height: 18px;
    width: 15px;
    color: var(--color-black);
}

.pointer {
    position: absolute;
    background: red;
    z-index: 9;
    inset-inline-start: 24px;
    width: 100px;
    height: 32px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;

    @include mobile {
        width: 68px;
        height: 26px;
        margin-block-end: 10px;
        inset-inline-start: 10px;
    }
}

.pointer h4 {
    text-align: center;
    color: #fff;
    padding-block-start: 5%;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: bolder;

    @include mobile {
        text-align: center;
        color: #fff;
        font-size: 11px;
        text-transform: uppercase;
        font-weight: bolder;
        vertical-align: middle;
    }
}

/*.pointer::before {
    content: "";
    position: absolute;
    inset-inline-end: -20px;
    inset-block-end: 0;
    width: 0;
    height: 0;
    border-left: 20px solid red;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;

    @include mobile {
        border-top: 13px solid transparent;
        border-bottom: 13px solid transparent;
    }
}*/
