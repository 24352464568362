/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.ProductActions {
    &-Title {
        color: var(--primary-blue-color);
        font-weight: bold;
        font-size: 32px;

        @include mobile {
            font-size: 20px;
        }
    }

    &-AddToCartWrapper {
        display: flex;
        flex-wrap: nowrap;
        grid-column-gap: 20px;
        column-gap: 20px;
        flex-grow: 1;

        .ProductActions-AddToCart {
            max-width: 250px;
            margin-inline-end: 0;
            margin-block-start: 0;

            @include mobile {
                max-width: unset;
            }
        }

        .Field-ErrorMessages {
            white-space: nowrap;
            margin-block-start: 5px;
        }
    }

    &-AddToCartFixed {
        display: flex;
        grid-column-gap: 20px;
        column-gap: 20px;
        flex-grow: 1;
        inset-block-end: 80px;

        .Field-ErrorMessages {
            margin-block-start: 0;
        }
    }

    &-PriceWrapper {
        margin: 35px 0;

        .ProductPrice_hasDiscount {
            .ProductPrice-PriceValue {
                color: #F43636;
                font-weight: 700;
            }
        }

        @include mobile {
            margin: 0px 0;
        }
    }

    &-Price {
        color: #0A0903;
        font-size: 32px;
        font-weight: 500;
        line-height: 35px;
        font-family: 'Roboto-bold', sans-serif;

        @include mobile {
            font-size: 20px;
        }
    }

    &-Divider {
        border-top: 1px solid var(--primary-grey-color);
        border-bottom: none;
        margin-block-start: 25px;

        @include mobile {
            margin-block-start: 0;
        }
    }

    .ExpandableContent {
        padding: 13px 0;
        cursor: auto;
        border-bottom: 0px solid var(--primary-grey-color);

        @include desktop {
            padding: 25px 0;
        }

        &-Heading {
            font-size: 20px;

            @include mobile {
                font-size: 16px;
            }
        }

        &-Button {
            cursor: pointer;

            @include desktop {
                display: flex;
            }
        }
    }

    &-ShortDescription * {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #6c6f7a;

        @include mobile {
            line-height: 22px;
            font-size: 14px;
        }

        li {
            display: flex;

            &::before {
                color: var(--secondary-blue-color);
                font-size: 25px;
                font-weight: bold;
                line-height: 22px;

                @include desktop {
                    line-height: 24px;
                }
            }
        }
    }

    &-QuantityWrapper {
        @include mobile {
            height: 44px;
            display: flex;
            align-items: center;
            /* stylelint-disable-next-line declaration-no-important */
            border: 1px solid var(--primary-blue-color) !important;
            border-radius: 5px;
            padding: 5px;
        }

        .CartItem-Qty {
            margin: 0;

            input {
                pointer-events: auto;
            }
        }
    }

    .CartItem-Qty {
        @include desktop {
            border-radius: 5px;
            padding: 4px;
            height: 48px;
            width: 110px;
            border: 1px solid #23346c;
        }

        input {
            width: 24px;
            height: 24px;
            min-width: 24px;
            min-height: 24px;
            font-size: 16px;
            color: var(--secondary-blue-color);
            border-radius: 5px;
            margin: 0 2px;
            padding-block-start: 2px;

            @include desktop {
                width: 34px;
                height: 34px;
            }
        }

        [type="button"] {
            width: 24px;
            height: 24px;
            border: solid 1px #e7f2f9;
            border-radius: 5px;

            @include desktop {
                width: 32px;
                height: 34px;
            }

            &:hover {
                background-color: #ddf7fe;
            }

            .AddIcon,
            .MinusIcon {
                fill: var(--primary-blue-color);
                width: 16px;
                height: 16px;

                @include desktop {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        [type="number"] {
            pointer-events: auto;
            font-size: 16px;
        }
    }

    &-OutOfStock {
        padding-inline-start: 25px;

        p {
            font-family: "Roboto-Bold", sans-serif;
            color: var(--primary-blue-color);
            font-size: 20px;
        }
    }

    @include mobile {
        margin-block-start: 30px;
    }
}

.ProductCustomizableItem-Wrapper {
    @include mobile {
        margin-block-start: 0px;
    }

    margin-block-end: 30px;
}

.ProductCustomizableItem-Wrapper .Field {
    @include mobile {
        margin-block-start: 10px;
    }
}

